.org {
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);

  & + & {
    margin-top: 2rem;
  }
}

.header {
  position: relative;
}

.name {
  font-size: 1.125rem;
  margin-bottom: 0;

  @media (min-width: 600px) {
    max-width: 70%;
  }
}

.areas-covered {
  display: grid;
  grid-template-columns: 18px 1fr;
  align-items: center;
  grid-column: 1/-1;
  width: 100%;
  font-size: 0.875rem;
  color: #888;
  margin-bottom: 0;
  margin-top: 5px;
  padding-bottom: 0.75rem;
}

.ac-link {
  color: #888;

  &:hover,
  &:active {
    text-decoration: none;
  }

  & + & {
    margin-left: 5px;
  }
}

.helping-with {
  margin-top: 0.5rem;
}

.hw-title {
  font-size: 0.875rem;
  color: #888;
  margin-bottom: 5px;
}

.hw-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.hw-items {
  background-color: #eee;
  letter-spacing: 0.5px;
  font-size: 0.875rem;
  padding: 2px 6px;
  border-radius: 4px;
  float: left;
  margin-bottom: 0.5rem;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.description {
  color: #888;
  @media (min-width: 600px) {
    padding-right: 1rem;
  }
}

.donation-cta {
  display: block;
  background-color: #cd454530;
  color: #cd4545;
  border-radius: 4px;
  min-width: 100px;
  text-align: center;
  padding: 0.75rem 1rem;
  text-decoration: none;
  transition: all 0.2s ease-in;

  &:hover,
  &:active {
    color: white;
    background: #cd4545;
  }
}

.cta-icon {
  vertical-align: middle;
  margin: -4px 2px 0 0;
}

:root {
  --contacts-height: 0px;
}

.contacts {
  margin-top: 1rem;
  overflow: hidden;
  height: var(--contacts-height);
  transition: all 0.4s ease-in-out;
}

.social {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  width: 100%;
  place-items: center;
  grid-gap: 0.75rem;
  grid-column: 1/-1;
  padding-top: 1rem;
  border-top: 1px solid #ddd;

  > * {
    line-height: 1;
  }
}

.website {
  font-size: 14px;
  color: #888;

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.facebook {
  color: #4267b2;
  text-decoration: none;
}

.twitter {
  color: #1da1f2;
  text-decoration: none;
}

.more-details {
  display: block;
  border: 0 none;
  justify-self: end;
  color: #888;
  background-color: #fff;
  cursor: pointer;
  grid-column: -1;

  transition: color 0.2s ease-in;

  &:hover,
  &:active {
    color: #cd4545;
  }
}

.more-details-icon {
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -2px;
}

// Organization Card Layout
.flex {
  @media (min-width: 600px) {
    display: flex;
  }
}

.flex-child {
  &:nth-child(1) {
    width: 100%;
    @media (min-width: 600px) {
      width: 70%;
    }
  }

  &:nth-child(2) {
    width: 100%;
    @media (min-width: 600px) {
      width: 30%;
    }
  }
}

// Need Help With
.need-help-with {
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.nhw-container {
  @extend .hw-container;
}

.nhw-items {
  @extend .hw-items;
}

.nhw-title {
  @extend .hw-title;
}
