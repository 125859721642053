.title {
  border-bottom: 2px solid #cd4545;

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
  }
}

.stats {
  border-bottom: 2px solid #cd4545;
  padding: 1rem 0 0;
  margin-bottom: 2rem;

  @media (min-width: 600px) {
    padding: 3rem 0 1rem;
  }
}

.helplines {
  font-size: 1.125rem;
  font-weight: 400;
  padding-bottom: 1rem;

  @media (min-width: 600px) {
    text-align: right;
  }
}

.content {
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;
  }
}

.links {
  margin-top: 2rem;
  white-space: break-word;

  @media (min-width: 1000px) {
    margin-top: 0;
  }
}

.link {
  white-space: nowrap;

  & + & {
    margin-top: 0.5rem;

    @media (min-width: 1000px) {
      margin-top: 1rem;
    }
  }
}

.tick-icon {
  margin-right: 8px;
  color: #888;
}

.update-data {
  display: block;
  grid-column: 1/-1;
  text-align: right;
  border-top: 1px solid #ddd;
  margin-top: 2rem;
  padding-top: 1rem;
  font-size: 14px;

  &:hover,
  &:active {
    text-decoration: none;
  }
}

.badge {
  font-size: small;
  background-color: #eee;
  padding: 4px 8px 5px;
  text-align: center;
  border-radius: 20px;
  display: inline-block;
  margin: 0 0 0 5px;
  letter-spacing: initial;
}
